import { KeyboardDoubleArrowDown } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React from "react";

const ScrollDown = (props) => {

  const {servicesRef} = props;

  const scrollDown = () => {
    servicesRef.current.scrollIntoView({ behavior: "smooth" })
  };

  return (
    <div
      className="scroll-down"
      style={{ display: "flex"}}
    >
      <IconButton
        color="inherit"
        aria-label="scroll down"
        edge="start"
        onClick={scrollDown}
      >
        <KeyboardDoubleArrowDown />
      </IconButton>
    </div>
  );
};

export default ScrollDown;

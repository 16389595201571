export const themeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: '#e3e0d8',
    },
    secondary: {
      main: '#8e986c',
    },
    background: {
      default: '#e3e0d8',
    },
    warning: {
      main: '#C16065',
    },
    divider: '#211c19',
  },
  spacing: 12,
  shape: {
    borderRadius: 8,
  },
  typography: {
    button: {
      fontWeight: 700,
    },
    fontFamily: 'Philosopher, sans-serif',
  },
};



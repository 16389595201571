import { useEffect, useRef } from "react";
import Image1 from "./images/acupunctureOne.jpg";
import ImageClinic from "./images/clinicOne.jpg";
import ImageClinicTwo from "./images/clinicTwo.jpg";
import Image3 from "./images/acupunctureThree.jpg";
import ImageGua from "./images/gua.jpg";
import ImageElectro from "./images/electroAcupuncture.jpg";
import ImageCupping from "./images/cupping.jpg";
import ImageMoxabustion from "./images/moxabustion.jpg";
import ImageTuiNa from "./images/tuina.jpg";
import ImageBACC from "./images/bacc.png";
import FirstSessionImage from "./images/firstvisit.jpeg";
import { themeOptions } from "./theme/theme";
import Lenis from "@studio-freight/lenis";
import { gsap } from "gsap";
import { preloader } from "./js/preloader";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ResponsiveAppBar from "./components/common/app-bar/app-bar";

import "./App.css";
import ScrollButton from "./components/common/scroll-to-top/scrollToTop";
import ScrollDown from "./components/common/scroll-down/scrollDown";

function App() {
  preloader();

  const theme = createTheme(themeOptions);

  const servicesRef = useRef();
  const aboutRef = useRef();
  const contactRef = useRef();
  const testimonialsRef = useRef();

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
  }, []);

  useEffect(() => {
    if (window) {
      const paths = [...document.querySelectorAll("path.path-anim")];

      const pathsFunc = () => {
        paths.forEach((el) => {
          const svgEl = el.closest("svg");
          const pathTo = el.dataset.pathTo;

          gsap
            .timeline({
              scrollTrigger: {
                trigger: svgEl,
                start: "top bottom",
                end: "bottom top",
                scrub: true,
              },
            })
            .to(el, {
              ease: "none",
              attr: { d: pathTo },
            });
        });
      };

      const lenis = new Lenis({
        lerp: 0.1,
        smooth: true,
      });

      const scrollFn = () => {
        lenis.raf();
        window.requestAnimationFrame(scrollFn);
      };
      window.requestAnimationFrame(scrollFn);

      pathsFunc();
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <div>
        <main>
          <ResponsiveAppBar
            servicesRef={servicesRef}
            aboutRef={aboutRef}
            contactRef={contactRef}
            testimonialsRef={testimonialsRef}
          />
          <section className="content content--centered landing">
            <div className="content__title">
              <h2>Carole Wilson</h2>
              <h1>Acupuncture</h1>
            </div>
            <div className="content__text">
              <p className="content__text-main">
                We aim to treat the root of your condition as well as your main
                symptoms. This approach helps with resolving your problem as
                well as enhancing your feeling of wellbeing.
              </p>
              <div className="bacc-image">
                <img alt="bacc member" src={ImageBACC} />
              </div>
            </div>
            <ScrollDown servicesRef={servicesRef} />
          </section>
          <section className="content about-us" ref={aboutRef}>
            <div
              className="content__bg content__bg--bottom"
              style={{ backgroundImage: `url(${Image3})` }}
            >
              <svg
                className="separator separator--up"
                width="100%"
                height="100%"
                viewBox="0 0 100 10"
                preserveAspectRatio="none"
              >
                <path
                  className="separator__path path-anim"
                  data-path-to="M 0 0 C 35 21 70 0 100 0 L 0 0 Z"
                  vectorEffect="non-scaling-stroke"
                  d="M 0 0 C 36.0637 9.8306 70 0 100 0 L 0 0 Z"
                />
              </svg>
              <svg
                className="separator separator--down"
                width="100%"
                height="100%"
                viewBox="0 0 100 10"
                preserveAspectRatio="none"
              >
                <path
                  className="separator__path path-anim"
                  data-path-to="M 0 0 C 29 6 78 17 100 0 L 100 10 H 0 Z"
                  vectorEffect="non-scaling-stroke"
                  d="M 0 0 C 18 1 61 9 100 0 L 100 10 H 0 Z"
                />
              </svg>
              <div className="content__title text-light">
                <h1 className="content__title-main">About Us</h1>
              </div>
            </div>
            <div className="container about-us-container">
              <div className="container-inner about-us-container-inner">
                <h2 className="container-inner-title about-us-container-inner-title container-text-right">
                  How we can help
                </h2>
                <p className="container-inner-text about-us-container-inner-text container-text-right">
                  Some people have acupuncture to help with a specific symptom
                  or condition. Others choose to have treatment to maintain good
                  health, as a preventative measure, or simply to improve their
                  sense of wellbeing. Because traditional acupuncture aims to
                  treat the whole person rather than specific symptoms in
                  isolation, it can be effective for a range of conditions.
                </p>
                <p className="container-inner-text about-us-container-inner-text container-text-right">
                  At Carole Wilson Acupuncture we aim to treat the root of your
                  condition as well as your main symptoms. This approach helps
                  with resolving your problem as well as enhancing your feeling
                  of wellbeing.
                </p>
                <p className="container-inner-text about-us-container-inner-text container-text-right">
                  You may notice other niggling problems resolve as your main
                  health complaint improves.
                </p>
                <p className="container-inner-text about-us-container-inner-text container-text-right">
                  <a
                    href="https://acupuncture.org.uk/about-acupuncture/acupuncture-research/evidence-a-z/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Find more information on the BACC website.
                  </a>
                </p>
              </div>
              <div className="container-inner about-us-container-inner">
                <img alt="acupuncture" src={Image1} />
              </div>
            </div>
            <div className="container about-us-container container-reverse">
              <div className="container-inner about-us-container-inner container-inner-reverse">
                <img alt="clinic" src={ImageClinicTwo} />
              </div>
              <div className="container-inner about-us-container-inner">
                <h2 className="container-inner-title about-us-container-inner-title">
                  The clinic
                </h2>
                <p className="container-inner-text about-us-container-inner-text">
                  The clinic is centrally located in Preston. It is a calm and
                  comfortable space. Parking is available nearby.
                </p>
                <p className="container-inner-text about-us-container-inner-text">
                  It is approximately 5 minutes walk from Preston Railway
                  Station. Bus routes 31 and 111 pass nearby. There is space to
                  secure a bicycle or scooter outside.
                </p>
              </div>
            </div>
            <div className="container about-us-container">
              <div className="container-inner about-us-container-inner">
                <h2 className="container-inner-title about-us-container-inner-title container-text-right">
                  First session
                </h2>
                <p className="container-inner-text about-us-container-inner-text container-text-right">
                  On your first appointment I will ask you all about your
                  presenting problem and how it affects you in your daily life.
                </p>
                <p className="container-inner-text about-us-container-inner-text container-text-right">
                  I will also need to know about your medical history, any
                  medication you take and any other factors in your life that
                  may be playing a part in your state of health. I will then
                  discuss a treatment plan with you and usually begin treatment
                  in the first session.
                </p>
                <p className="container-inner-text about-us-container-inner-text container-text-right">
                  First session £45
                </p>
              </div>
              <div className="container-inner about-us-container-inner">
                <img alt="clinic" src={FirstSessionImage} />
              </div>
            </div>
            <div className="container about-us-container container-reverse">
              <div className="container-inner about-us-container-inner container-inner-reverse">
                <img alt="clinic" src={ImageClinic} />
              </div>
              <div className="container-inner about-us-container-inner">
                <h2 className="container-inner-title about-us-container-inner-title">
                  Follow on sessions
                </h2>
                <p className="container-inner-text about-us-container-inner-text">
                  In normal circumstances a course of treatment is required. The
                  number of sessions needed is dependent on how you respond, how
                  long you have had the condition, how severe it is and your
                  general state of health.
                </p>
                <p className="container-inner-text about-us-container-inner-text">
                  The beneficial effects tend to increase and last longer over a
                  course of treatment and usually there are signs of change
                  early on in the process.
                </p>
                <p className="container-inner-text about-us-container-inner-text">
                  I will also be able to share with you methods of self care and
                  ways to maintain improvements in your health.
                </p>
                <p className="container-inner-text about-us-container-inner-text">
                  Follow up £40
                </p>
              </div>
            </div>
            <div className="container about-us-container">
              <div className="container-inner about-us-container-inner">
                <h2 className="container-inner-title about-us-container-inner-title container-text-right">
                  About me
                </h2>
                <p className="container-inner-text about-us-container-inner-text container-text-right">
                  Following a career in nursing I studied Acupuncture and
                  Acupressure massage at The Northern College of Acupuncture in
                  York, qualifying in 2001. As a member of the British
                  Acupuncture Council I am fully qualified and insured.
                </p>
                <p className="container-inner-text about-us-container-inner-text container-text-right">
                  I practice a traditional form of acupuncture which utilises an
                  understanding of health and illness that has developed over
                  thousands of years. The experience of seeing people benefit
                  from the treatment, feeling better physically and emotionally,
                  gives me enormous satisfaction and a deep respect for the
                  wisdom that it arose from.
                </p>
                <p className="container-inner-text about-us-container-inner-text container-text-right">
                  I continue to train and learn, not just as a requirement of my
                  professional body but also out of fascination with the
                  subject.
                </p>
              </div>
              <div className="container-inner about-us-container-inner">
                <div className="bacc-image">
                  <img alt="bacc member" src={ImageBACC} />
                </div>{" "}
              </div>
            </div>
          </section>
          <section className="content" ref={servicesRef}>
            <div
              className="content__bg content__bg--bottom"
              style={{ backgroundImage: `url(${Image3})` }}
            >
              <svg
                className="separator separator--up"
                width="100%"
                height="100%"
                viewBox="0 0 100 10"
                preserveAspectRatio="none"
              >
                <path
                  className="separator__path path-anim"
                  data-path-to="M 0 0 C 40 10 60 10 100 0 L 0 0 Z"
                  vectorEffect="non-scaling-stroke"
                  d="M 0 0 C 40 0 60 0 100 0 L 0 0 Z"
                />
              </svg>
              <svg
                className="separator separator--down"
                width="100%"
                height="100%"
                viewBox="0 0 100 10"
                preserveAspectRatio="none"
              >
                <path
                  className="separator__path path-anim"
                  data-path-to="M 0 0 C 25 13 75 13 100 0 L 100 10 H 0 Z"
                  vectorEffect="non-scaling-stroke"
                  d="M 0 0 C 40 0 60 0 100 0 L 100 10 H 0 Z"
                />
              </svg>
              <div className="content__title text-light">
                <h1 className="content__title-main">Our Services</h1>
              </div>
            </div>
          </section>
          <section className="content">
            <div className="container">
              <div className="container-inner">
                <h3 className="container-inner-title container-text-right">
                  Acupuncture
                </h3>
                <p className="container-inner-text container-text-right">
                  The practice of inserting fine needles into specific points on
                  the body to regulate body processes and functions.
                </p>
                <p className="container-inner-text container-text-right">
                  If you would like information regarding acupuncture and
                  specific conditions you can access that{" "}
                  <a
                    href="https://acupuncture.org.uk/about-acupuncture/acupuncture-research/evidence-a-z/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    here
                  </a>
                  . You also welcome to contact me with any questions.
                </p>
              </div>
              <div className="container-inner">
                <img src={Image3} alt="acupuncture" />
              </div>
            </div>
            <div className="container container-reverse">
              <div className="container-inner container-inner-reverse">
                <img src={ImageElectro} alt="electroacupuncture" />
              </div>
              <div className="container-inner">
                <h3 className="container-inner-title">Electroacupuncture</h3>
                <p className="container-inner-text">
                  This is the addition of a mild electrical current through the
                  acupuncture needles to enhance their effect.
                </p>
              </div>
            </div>
            <div className="container">
              <div className="container-inner">
                <h3 className="container-inner-title container-text-right">
                  Moxabustion
                </h3>
                <p className="container-inner-text container-text-right">
                  This is used to warm and stimulate the acupuncture points,
                  with or without needles. Moxa refers to the herb, Artemesia
                  argyi.
                </p>
              </div>

              <div className="container-inner">
                <img src={ImageMoxabustion} alt="moxabustion" />
              </div>
            </div>
            <div className="container container-reverse">
              <div className="container-inner container-inner-reverse">
                <img src={ImageTuiNa} alt="tui na" />
              </div>
              <div className="container-inner">
                <h3 className="container-inner-title">
                  Tui Na - Chinese Massage
                </h3>
                <p className="container-inner-text">
                  “Tui” translates as push and “Na” as grasp. A system of
                  massage therapy and manipulation to relieve pain and tension.
                </p>
              </div>
            </div>
            <div className="container">
              <div className="container-inner">
                <h3 className="container-inner-title container-text-right">
                  Cupping
                </h3>
                <p className="container-inner-text container-text-right">
                  The application of cups to the skin under suction used to
                  promote local blood flow resulting in muscle relaxation and
                  pain reduction.
                </p>
              </div>
              <div className="container-inner">
                <img src={ImageCupping} alt="cupping" />
              </div>
            </div>
            <div className="container container-reverse">
              <div className="container-inner container-inner-reverse">
                <img src={ImageGua} alt="gua" />
              </div>
              <div className="container-inner">
                <h3 className="container-inner-title">Gua Sha</h3>
                <p className="container-inner-text">
                  Press stroking on the skin with a tool in order to promote
                  blood flow and tissue release. Studies show Gua Sha to have an
                  anti inflammatory effect.
                </p>
              </div>
            </div>
          </section>
          <section className="content content--centered">
            <svg
              className="separator separator--solo separator--line"
              viewBox="0 0 900 300"
              width="900"
              height="400"
            >
              <defs>
                <clipPath id="shape6">
                  <rect x="0" y="0" width="900" height="290"></rect>
                </clipPath>
              </defs>
              <g clipPath="url(#shape6)">
                <path
                  className="path-anim"
                  vectorEffect="non-scaling-stroke"
                  data-path-to="M0,3 C16.6666667,33.6666667 75,-15 128.8,10.3 C174.770703,31.918193 272,-26 320,17 C368,60 431.272051,-18.4516816 525,10.3 C599,33 775,60 900,17 L900,304 C500,304 285.733333,304 257.2,304 C214.3,304 171.7,304 128.8,304 C100.266667,304 57.3333333,304 0,304 L0,3 Z"
                  d="M1,4 C17.6666667,34.6666667 52,50 104,50 C182,50 246.6,-27.4 386.8,11.3 C527,50 553,68 643.8,38.2 C704.333333,18.3333333 790.066667,11.6 901,18 L901,305 C501,305 286.733333,305 258.2,305 C215.3,305 172.7,305 129.8,305 C101.266667,305 58.3333333,305 1,305 L1,4 Z"
                ></path>
                <path
                  className="path-anim"
                  vectorEffect="non-scaling-stroke"
                  data-path-to="M0,90 C31.7333333,70.9333333 128.8,-21 226,66 C295.950087,128.609646 399,135 480,66 C561,-3 702.670114,107.309964 746,83 C853.3,22.8 866,101.666667 900,135 L900,304 L878.5,304 C857,304 685.7,304 642.8,304 C600,304 428.7,304 385.8,304 C343,304 300,304 257.2,304 C214.3,304 171.7,304 128.8,304 C86,304 43,304 21.5,304 L0,304 L0,90 Z"
                  d="M1,91 C32.7333333,71.9333333 75.6666667,82.8333333 129.8,123.7 C211,185 352,42 479,86 C606,130 694.4,114.4 772.2,100.2 C824.066667,90.7333333 867,102.666667 901,136 L901,305 L879.5,305 C858,305 686.7,305 643.8,305 C601,305 429.7,305 386.8,305 C344,305 301,305 258.2,305 C215.3,305 172.7,305 129.8,305 C87,305 44,305 22.5,305 L1,305 L1,91 Z"
                ></path>
                <path
                  className="path-anim"
                  vectorEffect="non-scaling-stroke"
                  data-path-to="M0,149.2 C52.6666667,137.866667 45,100 128.8,173.5 C188.188882,225.589294 203,75.9791366 284,149.2 C365,222.420863 421,105 478,173.5 C535,242 554,253 596,186 C638,119 721.4,83 771.2,159.5 C821,236 865.6,189.666667 900,207 L900,311 L878.5,311 C857,311 814,311 771.2,311 C728.3,311 685.7,311 642.8,311 C600,311 557,311 514.2,311 C471.3,311 428.7,311 385.8,311 C343,311 300,311 257.2,311 C214.3,311 171.7,311 128.8,311 C86,311 43,311 21.5,311 L0,311 L0,149.2 Z"
                  d="M1,139 C53.6666667,127.666667 96.6,139.5 129.8,174.5 C179.6,227 242.1,149.3 285,150.2 C327.9,151.1 322.6,219 386.8,181.5 C451,144 478,187 597,187 C716,187 695,139 772.2,160.5 C823.666667,174.833333 866.6,190.666667 901,208 L901,312 L879.5,312 C858,312 815,312 772.2,312 C729.3,312 686.7,312 643.8,312 C601,312 558,312 515.2,312 C472.3,312 429.7,312 386.8,312 C344,312 301,312 258.2,312 C215.3,312 172.7,312 129.8,312 C87,312 44,312 22.5,312 L1,312 L1,139 Z"
                ></path>
                <path
                  className="path-anim"
                  vectorEffect="non-scaling-stroke"
                  data-path-to="M0,242 C57.3333333,235.333333 27.6,275 128.8,231.5 C230,188 197.2,242.2 291,270 C384.8,297.8 444,209.729976 543,248.729976 C642,287.729976 689.4,223 771.2,223 C853,223 842.666667,272.333333 900,263 L900,304 L0,304 L0,242 Z"
                  d="M1,243 C58.3333333,236.333333 101.266667,232.833333 129.8,232.5 C172.7,232 164.4,204.4 258.2,232.2 C352,260 380,165 479,204 C578,243 729.3,230 772.2,224 C800.733333,220 843.666667,213.333333 901,204 L901,305 L1,305 L1,243 Z"
                ></path>
              </g>
            </svg>
            <div
              className="content__title content-title-margin-bottom"
              ref={testimonialsRef}
            >
              <h1 className="content__title-main">Client Testimonials</h1>
            </div>
            <div className="container-testimonials">
              <p>
                "I always feel so rejuvenated and happier following a treatment
                with Carole Wilson."
              </p>
              <p>
                "The effects from these treatments have always been positive and
                have afforded me considerable physical relief from symptoms."
              </p>
              <p>
                "Carole Wilson is a genuine, lovely, calm, skilled professional
                who will put you totally at your ease from the outset."
              </p>
              <p>
                "Carole Wilson helped me cope better with menopause symptoms and
                more recently age related arthritic pain."
              </p>
              <p>
                "I have been treated for several years on and off for various
                problems. Carole helped me cope better with menopause symptoms
                and more recently age related arthritic pain. She is a
                sympathetic and professional practitioner and very
                knowledgeable. I have found that her massage and acupuncture has
                been invaluable help for my physical pain. She is also very easy
                to talk to. I would recommend Carole wholeheartedly."
              </p>
              <p>
                "I have been having treatment with Carole over many years for a
                number of ailments but predominantly my droopy eye lid and a bad
                back following a lower disc removal. I found it hard to explain
                my embarrassment regarding my eye issue but she listened and
                formulated a plan to address my misaligned brows to give a more
                uniform appearance of parity. Carole has helped me using a
                number of alternative therapies including acupuncture, massage,
                cupping and moxa. The effects from these treatments have always
                been positive and have afforded me considerable relief from
                symptoms."
              </p>
            </div>
            <svg
              className="separator separator--solo separator--line"
              viewBox="0 0 900 300"
              width="900"
              height="400"
            >
              <defs>
                <clipPath id="shape7">
                  <rect x="0" y="0" width="900" height="290"></rect>
                </clipPath>
              </defs>
              <g clipPath="url(#shape7)">
                <path
                  className="path-anim"
                  vectorEffect="non-scaling-stroke"
                  data-path-to="M0,3 C16.6666667,33.6666667 75,-15 128.8,10.3 C174.770703,31.918193 272,-26 320,17 C368,60 431.272051,-18.4516816 525,10.3 C599,33 775,60 900,17 L900,304 C500,304 285.733333,304 257.2,304 C214.3,304 171.7,304 128.8,304 C100.266667,304 57.3333333,304 0,304 L0,3 Z"
                  d="M1,4 C17.6666667,34.6666667 52,50 104,50 C182,50 246.6,-27.4 386.8,11.3 C527,50 553,68 643.8,38.2 C704.333333,18.3333333 790.066667,11.6 901,18 L901,305 C501,305 286.733333,305 258.2,305 C215.3,305 172.7,305 129.8,305 C101.266667,305 58.3333333,305 1,305 L1,4 Z"
                ></path>
                <path
                  className="path-anim"
                  vectorEffect="non-scaling-stroke"
                  data-path-to="M0,90 C31.7333333,70.9333333 128.8,-21 226,66 C295.950087,128.609646 399,135 480,66 C561,-3 702.670114,107.309964 746,83 C853.3,22.8 866,101.666667 900,135 L900,304 L878.5,304 C857,304 685.7,304 642.8,304 C600,304 428.7,304 385.8,304 C343,304 300,304 257.2,304 C214.3,304 171.7,304 128.8,304 C86,304 43,304 21.5,304 L0,304 L0,90 Z"
                  d="M1,91 C32.7333333,71.9333333 75.6666667,82.8333333 129.8,123.7 C211,185 352,42 479,86 C606,130 694.4,114.4 772.2,100.2 C824.066667,90.7333333 867,102.666667 901,136 L901,305 L879.5,305 C858,305 686.7,305 643.8,305 C601,305 429.7,305 386.8,305 C344,305 301,305 258.2,305 C215.3,305 172.7,305 129.8,305 C87,305 44,305 22.5,305 L1,305 L1,91 Z"
                ></path>
                <path
                  className="path-anim"
                  vectorEffect="non-scaling-stroke"
                  data-path-to="M0,149.2 C52.6666667,137.866667 45,100 128.8,173.5 C188.188882,225.589294 203,75.9791366 284,149.2 C365,222.420863 421,105 478,173.5 C535,242 554,253 596,186 C638,119 721.4,83 771.2,159.5 C821,236 865.6,189.666667 900,207 L900,311 L878.5,311 C857,311 814,311 771.2,311 C728.3,311 685.7,311 642.8,311 C600,311 557,311 514.2,311 C471.3,311 428.7,311 385.8,311 C343,311 300,311 257.2,311 C214.3,311 171.7,311 128.8,311 C86,311 43,311 21.5,311 L0,311 L0,149.2 Z"
                  d="M1,139 C53.6666667,127.666667 96.6,139.5 129.8,174.5 C179.6,227 242.1,149.3 285,150.2 C327.9,151.1 322.6,219 386.8,181.5 C451,144 478,187 597,187 C716,187 695,139 772.2,160.5 C823.666667,174.833333 866.6,190.666667 901,208 L901,312 L879.5,312 C858,312 815,312 772.2,312 C729.3,312 686.7,312 643.8,312 C601,312 558,312 515.2,312 C472.3,312 429.7,312 386.8,312 C344,312 301,312 258.2,312 C215.3,312 172.7,312 129.8,312 C87,312 44,312 22.5,312 L1,312 L1,139 Z"
                ></path>
                <path
                  className="path-anim"
                  vectorEffect="non-scaling-stroke"
                  data-path-to="M0,242 C57.3333333,235.333333 27.6,275 128.8,231.5 C230,188 197.2,242.2 291,270 C384.8,297.8 444,209.729976 543,248.729976 C642,287.729976 689.4,223 771.2,223 C853,223 842.666667,272.333333 900,263 L900,304 L0,304 L0,242 Z"
                  d="M1,243 C58.3333333,236.333333 101.266667,232.833333 129.8,232.5 C172.7,232 164.4,204.4 258.2,232.2 C352,260 380,165 479,204 C578,243 729.3,230 772.2,224 C800.733333,220 843.666667,213.333333 901,204 L901,305 L1,305 L1,243 Z"
                ></path>
              </g>
            </svg>
          </section>
          <section className="content">
            <div
              className="content__bg content__bg--bottom content__bg--green"
              style={{ backgroundImage: `url(${Image3})` }}
            >
              <svg
                className="separator separator--up"
                width="100%"
                height="100%"
                viewBox="0 0 100 10"
                preserveAspectRatio="none"
              >
                <path
                  className="separator__path path-anim"
                  data-path-to="M 0 0 C 35 21 70 0 100 0 L 0 0 Z"
                  vectorEffect="non-scaling-stroke"
                  d="M 0 0 C 36.0637 9.8306 70 0 100 0 L 0 0 Z"
                />
              </svg>
              <svg
                className="separator separator--down separator-green"
                width="100%"
                height="100%"
                viewBox="0 0 100 10"
                preserveAspectRatio="none"
              >
                <path
                  className="separator__path path-anim"
                  data-path-to="M 0 0 C 29 6 78 17 100 0 L 100 10 H 0 Z"
                  vectorEffect="non-scaling-stroke"
                  d="M 0 0 C 18 1 61 9 100 0 L 100 10 H 0 Z"
                />
              </svg>
              <div className="content__title text-light">
                <h1 className="content__title-main">Contact Us</h1>
              </div>
            </div>
          </section>
          <section className="content contact-form" ref={contactRef}>
            <div className="container contact-form-container">
              <div className="container-inner contact-form-container-inner">
                <h2>Get in touch!</h2>
                <p>
                  Please contact me with your questions regarding acupuncture
                  and what it might do for you.
                </p>
              </div>
              <div className="container-inner contact-form-container-inner">
                <h3>Email:</h3>
                <a className="contact-links" href="mailto:carolewilsonacupuncture@yahoo.com">
                  carolewilsonacupuncture@yahoo.com
                </a>
                <h3>Phone:</h3>
                <a className="contact-links" href="tel:07947439309">07947 439309</a>
              </div>
            </div>
          </section>
          <section className="content footer">
            <div className="container map-container">
              <div className="container-inner map-container-inner">
                <h3>Monday, Wednesday, Thursday</h3>
                <div className="contact-details-address">
                  <p>Cliff Street Clinic</p>
                  <p>2a Cliff street</p>
                  <p>Preston</p>
                  <p>Lancashire</p>
                  <p>PR1 8HY</p>
                  <p>
                    Mob <a href="tel:07947439309">07947 439309</a>
                  </p>
                </div>
                <div className="google-map-container">
                  <iframe
                    title="cliff-street"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1179.4832065939624!2d-2.713166084111111!3d53.754481132880116!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487b727f255799bd%3A0x437766bd322de2f6!2sCliff%20St%2C%20Preston%20PR1%208HY!5e0!3m2!1sen!2suk!4v1689174005728!5m2!1sen!2suk"
                    width="300"
                    height="300"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
              <div className="container-inner map-container-inner">
                <h3>Tuesday</h3>
                <div className="contact-details-address">
                  <p>The Towngate Clinic</p>
                  <p>102 Towngate</p>
                  <p>Leyland</p>
                  <p>Lancashire</p>
                  <p>PR25 2LR</p>
                  <p>
                    Mob <a href="tel:07947439309">07947 439309</a>
                  </p>
                </div>
                <div className="google-map-container">
                  <iframe
                    title="towngate"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2362.5130406209673!2d-2.700134073452226!3d53.69130379885713!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487b0d5b7118856d%3A0x7defb7ca485f3973!2sTowngate%2C%20Leyland%20PR25%202LR!5e0!3m2!1sen!2suk!4v1689174242232!5m2!1sen!2suk"
                    width="300"
                    height="300"
                    allowfullscreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
            <svg
              className="separator separator--solo separator--line-light"
              viewBox="0 0 900 300"
              width="900"
              height="400"
            >
              <defs>
                <clipPath id="shape12">
                  <rect x="0" y="0" width="900" height="290"></rect>
                </clipPath>
              </defs>
              <g clipPath="url(#shape12)">
                <path
                  className="path-anim"
                  vectorEffect="non-scaling-stroke"
                  data-path-to="M0,3 C16.6666667,33.6666667 75,-15 128.8,10.3 C174.770703,31.918193 272,-26 320,17 C368,60 431.272051,-18.4516816 525,10.3 C599,33 775,60 900,17 L900,304 C500,304 285.733333,304 257.2,304 C214.3,304 171.7,304 128.8,304 C100.266667,304 57.3333333,304 0,304 L0,3 Z"
                  d="M1,4 C17.6666667,34.6666667 52,50 104,50 C182,50 246.6,-27.4 386.8,11.3 C527,50 553,68 643.8,38.2 C704.333333,18.3333333 790.066667,11.6 901,18 L901,305 C501,305 286.733333,305 258.2,305 C215.3,305 172.7,305 129.8,305 C101.266667,305 58.3333333,305 1,305 L1,4 Z"
                ></path>
                <path
                  className="path-anim"
                  vectorEffect="non-scaling-stroke"
                  data-path-to="M0,90 C31.7333333,70.9333333 128.8,-21 226,66 C295.950087,128.609646 399,135 480,66 C561,-3 702.670114,107.309964 746,83 C853.3,22.8 866,101.666667 900,135 L900,304 L878.5,304 C857,304 685.7,304 642.8,304 C600,304 428.7,304 385.8,304 C343,304 300,304 257.2,304 C214.3,304 171.7,304 128.8,304 C86,304 43,304 21.5,304 L0,304 L0,90 Z"
                  d="M1,91 C32.7333333,71.9333333 75.6666667,82.8333333 129.8,123.7 C211,185 352,42 479,86 C606,130 694.4,114.4 772.2,100.2 C824.066667,90.7333333 867,102.666667 901,136 L901,305 L879.5,305 C858,305 686.7,305 643.8,305 C601,305 429.7,305 386.8,305 C344,305 301,305 258.2,305 C215.3,305 172.7,305 129.8,305 C87,305 44,305 22.5,305 L1,305 L1,91 Z"
                ></path>
                <path
                  className="path-anim"
                  vectorEffect="non-scaling-stroke"
                  data-path-to="M0,149.2 C52.6666667,137.866667 45,100 128.8,173.5 C188.188882,225.589294 203,75.9791366 284,149.2 C365,222.420863 421,105 478,173.5 C535,242 554,253 596,186 C638,119 721.4,83 771.2,159.5 C821,236 865.6,189.666667 900,207 L900,311 L878.5,311 C857,311 814,311 771.2,311 C728.3,311 685.7,311 642.8,311 C600,311 557,311 514.2,311 C471.3,311 428.7,311 385.8,311 C343,311 300,311 257.2,311 C214.3,311 171.7,311 128.8,311 C86,311 43,311 21.5,311 L0,311 L0,149.2 Z"
                  d="M1,139 C53.6666667,127.666667 96.6,139.5 129.8,174.5 C179.6,227 242.1,149.3 285,150.2 C327.9,151.1 322.6,219 386.8,181.5 C451,144 478,187 597,187 C716,187 695,139 772.2,160.5 C823.666667,174.833333 866.6,190.666667 901,208 L901,312 L879.5,312 C858,312 815,312 772.2,312 C729.3,312 686.7,312 643.8,312 C601,312 558,312 515.2,312 C472.3,312 429.7,312 386.8,312 C344,312 301,312 258.2,312 C215.3,312 172.7,312 129.8,312 C87,312 44,312 22.5,312 L1,312 L1,139 Z"
                ></path>
                <path
                  className="path-anim"
                  vectorEffect="non-scaling-stroke"
                  data-path-to="M0,242 C57.3333333,235.333333 27.6,275 128.8,231.5 C230,188 197.2,242.2 291,270 C384.8,297.8 444,209.729976 543,248.729976 C642,287.729976 689.4,223 771.2,223 C853,223 842.666667,272.333333 900,263 L900,304 L0,304 L0,242 Z"
                  d="M1,243 C58.3333333,236.333333 101.266667,232.833333 129.8,232.5 C172.7,232 164.4,204.4 258.2,232.2 C352,260 380,165 479,204 C578,243 729.3,230 772.2,224 C800.733333,220 843.666667,213.333333 901,204 L901,305 L1,305 L1,243 Z"
                ></path>
              </g>
            </svg>
          </section>
          <ScrollButton />
        </main>
      </div>
    </ThemeProvider>
  );
}

export default App;

import * as React from "react";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import ImageBACC from "../../../images/bacc.png";

const drawerWidth = 280;

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

function DrawerAppBar(props) {
  const { window, aboutRef, servicesRef, contactRef, testimonialsRef } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center" }}
      className="menu-drawer"
    >
      <div className="content__title menu-content-title">
        <span className="content__title-pre content__title-pre--offset">
          Carole Wilson
        </span>
        <h2 className="content__title-main">Acupuncture</h2>
      </div>

      <List className="drawer-menu-list">
        
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center" }}
            onClick={() =>
              aboutRef.current.scrollIntoView({ behavior: "smooth" })
            }
          >
            <ListItemText primary={"About"} className="drawer-menu-text" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center" }}
            onClick={() =>
              servicesRef.current.scrollIntoView({ behavior: "smooth" })
            }
          >
            <ListItemText primary={"Services"} className="drawer-menu-text" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center" }}
            onClick={() =>
              testimonialsRef.current.scrollIntoView({ behavior: "smooth" })
            }
          >
            <ListItemText primary={"Testimonials"} className="drawer-menu-text" />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            sx={{ textAlign: "center" }}
            onClick={() =>
              contactRef.current.scrollIntoView({ behavior: "smooth" })
            }
          >
            <ListItemText primary={"Contact"} className="drawer-menu-text" />
          </ListItemButton>
        </ListItem>
      </List>
     
      <div className="drawer-menu-contact-details">
        <div className="drawer-menu-contact-details-address">
          <p className="details-title">Mon, Wed, Thurs</p>
          <p>Cliff Street Clinic</p>
          <p>2a Cliff street</p>
          <p>Preston</p>
          <p>Lancashire</p>
          <p>PR1 8HY</p>
          <p className="details-number">
            Mob <a href="tel:07947439309">07947 439309</a>
          </p>
        </div>
        <div className="drawer-menu-contact-details-address">
          <p className="details-title">Tuesday</p>
          <p>The Towngate Clinic</p>
          <p>102 Towngate</p>
          <p>Leyland</p>
          <p>Lancashire</p>
          <p>PR25 2LR</p>

          <p className="details-number">
            Tel <a href="tel:01772456657">01772 456657</a>
          </p>
          <p className="details-number">
            Mob <a href="tel:07947439309">07947 439309</a>
          </p>
        </div>
        <div className="drawer-menu-contact-details-address">
          <p className="details-email">
            <a href="mailto:carolewilsonacupuncture@yahoo.com">
              carolewilsonacupuncture@yahoo.com
            </a>
          </p>
        </div>
      </div>
      <div className="bacc-image">
                <img alt="bacc member" src={ImageBACC} />
              </div>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <HideOnScroll {...props}>
      <Box sx={{ display: "flex" }}>
        <AppBar component="nav" className="app-bar-main">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ display: { sm: "none" } }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
            <Button
                sx={{ color: "#fff" }}
                onClick={() =>
                  aboutRef.current.scrollIntoView({ behavior: "smooth" })
                }
              >
                {"About"}
              </Button>
              <Button
                sx={{ color: "#fff" }}
                onClick={() =>
                  servicesRef.current.scrollIntoView({ behavior: "smooth" })
                }
              >
                {"Services"}
              </Button>
              <Button
                sx={{ color: "#fff" }}
                onClick={() =>
                  testimonialsRef.current.scrollIntoView({ behavior: "smooth" })
                }
              >
                {"Testimonials"}
              </Button>
              <Button
                sx={{ color: "#fff" }}
                onClick={() =>
                  contactRef.current.scrollIntoView({ behavior: "smooth" })
                }
              >
                {"Contact"}
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
        <Box component="nav">
          <Drawer
            container={container}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
              },
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </Box>
    </HideOnScroll>
  );
}

export default DrawerAppBar;
